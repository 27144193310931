import {
	GET_AUTOSHIP_PRODUCTS, GET_AUTOSHIP, GET_AUTOSHIP_TOTALS,
	UPDATE_AUTOSHIP, ADD_AUTOSHIP_PRODUCT, SET_NEXT_AUTOSHIP_DATE,
	SET_AUTOSHIP_PERIODICITY, FILL_AFFILIATE_AUTOSHIP,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class AutoshipInformation {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getProducts(userID) {
		const { method, endpoint } = GET_AUTOSHIP_PRODUCTS;
		return this.data[method](endpoint(userID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAutoship(userID) {
		const { method, endpoint } = GET_AUTOSHIP;
		return this.data[method](endpoint(userID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateAutoship(userID, payload) {
		const { method, endpoint } = UPDATE_AUTOSHIP;
		return this.data[method](endpoint(userID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateAutoshipPeriodicity(userID, payload) {
		const { method, endpoint } = SET_AUTOSHIP_PERIODICITY;
		return this.data[method](endpoint(userID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	addProduct(userID, payload) {
		const { method, endpoint } = ADD_AUTOSHIP_PRODUCT;
		return this.data[method](endpoint(userID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getTotals(userID) {
		const { method, endpoint } = GET_AUTOSHIP_TOTALS;
		return this.data[method](endpoint(userID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	setAutoshipDate(userID, payload) {
		const { method, endpoint } = SET_NEXT_AUTOSHIP_DATE;
		return this.data[method](endpoint(userID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	affiliateUpdateAutoship(payload) {
		const { method, endpoint } = FILL_AFFILIATE_AUTOSHIP;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default AutoshipInformation;
